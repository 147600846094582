import { isoLocale } from 'localization';
import {
  MetaTags,
  Rel,
} from '@/config/meta-tags';
import {
  type HeadData,
  type HeadLink,
} from '@/types/head-data';

const usePageHead = () => {
  const i18nHead = useLocaleHead({
    addDirAttribute: true,
    addSeoAttributes: true,
  });
  const route = useRoute();

  const getPageHead = (headData: HeadData) => {
    const i18nHeadLinks = i18nHead.value.link as HeadLink[];

    const openGraphMeta = createOpenGraphMeta({
      description: headData.description || '',
      title: headData.title || '',
      url: route.fullPath,
    });

    const filteredI18nHrefLangs = i18nHeadLinks.filter((headLink) => !(headLink.rel === Rel.Alternate
      && !(headLink.hreflang && isoLocale.includes(headLink.hreflang))));

    return {
      ...i18nHead.value,
      link: headData.links || filteredI18nHrefLangs,
      meta: [
        ...i18nHead.value.meta,
        {
          content: headData.description,
          hid: MetaTags.Description,
          name: MetaTags.Description,
        },
        {
          content: headData.robots,
          hid: MetaTags.Robots,
          name: MetaTags.Robots,
        },
        ...openGraphMeta,
      ],
      title: headData.title,
    };
  };

  return { getPageHead };
};

export { usePageHead };
