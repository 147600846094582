/* eslint-disable perfectionist/sort-objects */
import {
  FB_PAGE_ID,
  TWITTER_CREATOR,
  TWITTER_SITE,
} from '@/config/seo';

interface OpenGraphMetaOptions {
  description: string;
  title: string;
  url: string;
}

export const createOpenGraphMeta = ({
  description,
  title,
  url,
}: OpenGraphMetaOptions) => [
  {
    property: 'og:site_name',
    content: 'JUNIQE',
  },
  {
    property: 'og:type',
    content: 'product',
  },
  {
    property: 'og:title',
    content: title,
  },
  {
    property: 'og:description',
    content: description,
  },
  {
    property: 'og:url',
    content: url,
  },
  {
    property: 'og:image',
    content: '/juniqe.jpg',
  },
  {
    property: 'fb:page_id',
    content: FB_PAGE_ID,

  },
  {
    property: 'twitter:site',
    content: TWITTER_SITE,
  },
  {
    property: 'twitter:title',
    content: title,
  },
  {
    property: 'twitter:description',
    content: description,
  },
  {
    property: 'twitter:image',
    content: '/juniqe.jpg',
  },
  {
    property: 'twitter:creator',
    content: TWITTER_CREATOR,
  },
];
